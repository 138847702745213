<template>
  <div>
    <div style="height: 30px;margin: 25px 0;display: flex;align-items: center">
      <el-breadcrumb class="bread" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breaditem" style="margin-right: 0" :to="{path: '/back'}">
          <span style="color: #909399;font-weight: normal;">退货单</span>
        </el-breadcrumb-item>
        <el-breadcrumb-item class="breaditem" style="margin-left: 0">
          <span style="color: #909399">新增退货</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="selectdiv">
      <el-date-picker v-model="date" size="small" type="daterange" unlink-panels
                      :clearable="false" class="el-date-picker family"
                      style="width: 240px !important;margin-right: 10px"
                      range-separator="/" format="yyyy-MM-dd" value-format="timestamp" :editable="false"/>
      <el-input class="family" size="small" v-model.trim="query.isbn" placeholder="书号"
                @keyup.enter.native="selectBack" clearable maxlength="20"
                style="width: 200px !important;margin-right: 10px">
      </el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="selectBack">查询</el-button>
    </div>
    <div style="height: 70px">
      <div style="height: 47px"/>
      <div v-if="total>0" style="height: 23px;line-height: 13px">
        <span class="span" style="color: #606266">搜索出 </span>
        <span class="span" style="color: #409EFF">{{total}}</span>
        <span class="span" style="color: #606266"> 条结果 </span>
      </div>
    </div>
    <el-table border stripe size="small" :data="backs" :style="{width: '844px'}"
              :row-style="{height:'42px'}"
              :header-cell-style="{background:'#ECF5FF',fontWeight: 'normal'}">
      <el-table-column label="订单号 / 订货时间 / 供货商" width="270" :resizable="false">
        <template slot-scope="scope">
          <span style="margin-right: 25px">{{'#' + (Array(7).join('0') + scope.row.order.id).slice(-7)}}</span>
          <span>{{timeFormat(scope.row.order.time)}}</span>
          <el-divider/>
          <span style="margin-right: 5px">{{scope.row.order.seller.name}}</span>
          <el-tag type="primary">{{getFormat(scope.row.order.seller.getmode)}}</el-tag>
          <el-tag style="margin-right: 0" type="danger">{{backFormat(scope.row.order.seller.backmode)}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="书号 / 定价 / 书名" width="320" :resizable="false">
        <template slot-scope="scope">
          <span style="margin-right: 25px">{{scope.row.book.isbn}}</span>
          <span>{{'￥'+priceFormat(scope.row.book.price)}}</span>
          <el-divider/>
          <span>{{scope.row.book.title}}</span>
        </template>
      </el-table-column>
      <el-table-column label="折扣 / 库位" width="130" :resizable="false">
        <template slot-scope="scope">
          <span>{{priceFormat(scope.row.order.detail.discount)}}</span>
          <el-divider/>
          <span :style="{color: scope.row.order.detail.position===undefined?'#C0C4CC':'#606266'}">
            {{scope.row.order.detail.position===undefined?
            '暂无库位':scope.row.order.detail.position}}</span>
        </template>
      </el-table-column>
      <el-table-column label="可退" prop="backNum" width="60" :resizable="false"/>
      <el-table-column label="退货" width="63" :resizable="false" header-align="center">
        <template slot-scope="scope">
          <div class="scope">
            <el-popover placement="right" v-model="scope.row.visibleAdd">
              <div style="display: flex;justify-content: center;align-items: center">
                <el-input-number class="family1" v-model="scope.row.num" size="mini"
                                 :min="1" :max="scope.row.backNum"/>
                <el-button class="buttonPopo" size="mini" type="success" @click="addBack(scope.row)"
                           icon="el-icon-check" :loading="scope.row.loadingAdd"/>
              </div>
              <el-button slot="reference" class="buttonTable" size="mini"
                         icon="el-icon-plus" :loading="scope.row.loadingAdd"
                         @click="initBack(scope.row)"
                         :type="scope.row.backNum===0?'info':'success'"
                         :disabled="scope.row.backNum===0"/>
            </el-popover>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination background layout="prev,pager,next" :page-size="50" :total="total"
                   :current-page="query.page" @current-change="pageChange"
                   :hide-on-single-page="true"/>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        query: {
          begin: '',
          end: '',
          isbn: '',
          page: 1
        },
        backs: [],
        total: 0,
        date: [Date.now() - 6 * 86400000, Date.now()]
      }
    },
    methods: {
      timeFormat (value) {
        const date = new Date(value)
        let MM = date.getMonth() + 1
        MM = MM < 10 ? ('0' + MM) : MM
        let d = date.getDate()
        d = d < 10 ? ('0' + d) : d
        let h = date.getHours()
        h = h < 10 ? ('0' + h) : h
        let m = date.getMinutes()
        m = m < 10 ? ('0' + m) : m
        return MM + '-' + d + '　' + h + ':' + m
      },
      priceFormat (value) {
        return parseFloat(value).toFixed(2)
      },
      getFormat (value) {
        if (value === '0') {
          return '自退'
        } else if (value === '1') {
          return '兴茂'
        } else if (value === '2') {
          return '代发'
        } else {
          return '书店宝'
        }
      },
      backFormat (value) {
        if (value === '0') {
          return '不可退'
        } else if (value === '1') {
          return '1天可退'
        } else if (value === '2') {
          return '3天可退'
        } else if (value === '3') {
          return '7天可退'
        } else if (value === '4') {
          return '15天可退'
        } else {
          return '30天可退'
        }
      },

      selectBack () {
        this.query.page = 1
        this.getBackList()
      },
      pageChange (page) {
        this.query.page = page
        this.getBackList()
      },

      async getBackList () {
        if (this.query.isbn === '') {
          return this.$msg.warning('请输入书号！')
        }

        this.query.begin = this.date[0]
        this.query.end = this.date[1]
        const loading = this.loading()

        try {
          const { data: res } = await this.$http.get('getBackListByAdd', { params: this.query })
          loading.close()

          if (!this.isLogin(res)) {
            loading.close()
            return
          }
          if (res.code !== 0) {
            this.total = 0
            this.backs = []
            loading.close()
            this.$msg.warning(res.msg)
            return
          }

          this.total = res.data.total
          this.backs = res.data.data
          this.backs = this.backs.map(item => {
            this.$set(item, 'num', 1)
            this.$set(item, 'visibleAdd', false)
            this.$set(item, 'loadingAdd', false)
            return item
          })
          if (this.total === 0) {
            this.$msg.warning('暂无可退货订单，请尝试修改订货时间！')
          } else {
            this.query.isbn = ''
          }

          document.documentElement.scrollTop = document.body.scrollTop = 0
        } catch (e) {
          this.total = 0
          this.backs = []
          loading.close()
          this.$msg.error(this.netError)
        }
      },
      async addBack (row) {
        if (row.num === undefined) {
          this.$msg.warning('请输入退货数量！')
          return
        }
        this.$set(row, 'visibleAdd', false)
        this.$set(row, 'loadingAdd', true)

        try {
          const { data: res } = await this.$http.post('addBack',
            {
              orderId: row.order.id,
              bookId: row.book.id,
              backNum: row.num
            })
          this.$set(row, 'loadingAdd', false)

          if (!this.isLogin(res)) {
            return
          }
          if (res.code !== 0) {
            this.$msg.warning(res.msg)
            return
          }

          this.$set(row, 'backNum', row.backNum - row.num)
          this.$msg.success('新增成功！')
        } catch (e) {
          this.$set(row, 'loadingAdd', false)
          this.$msg.error(this.netError)
        }
      },
      initBack (row) {
        row.num = 1
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .family input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .family1 input {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px;
  }

  /deep/ .el-button {
    height: 32px !important;
    font-size: 13px;
    padding: 0 15px;
  }

  .scope {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .buttonTable {
    width: 32px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 5px;
  }

  /deep/ .buttonTableText {
    width: 40px !important;
    height: 22px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 10px;
  }

  /deep/ .buttonTableText1 {
    width: 12px !important;
    height: 12px !important;
    font-size: 12px;
    padding: 0;
    margin-left: 3px;
    margin-bottom: 2px;
    color: #F56C6C;
  }

  .span {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  .spanPopo {
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 13px;
  }

  /deep/ .buttonPopo {
    width: 40px !important;
    height: 28px !important;
    font-size: 12px;
    padding: 0;
    margin: 0 0 0 10px;
  }

  /deep/ .el-table td,
  /deep/ .el-table th.is-leaf {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border th,
  /deep/ .el-table--border th.gutter:last-of-type {
    border-bottom: 0.5px solid #EBEEF5 !important;
  }

  /deep/ .el-table--border td,
  /deep/ .el-table--border th {
    border-right: 0.5px solid #EBEEF5 !important;
  }

  .el-divider--horizontal {
    margin: 1px 0;
    height: 1px;
  }

  .el-divider {
    background-color: #0000000c
  }

  .el-divider--vertical {
    margin: 0 20px;
  }

  .el-input-number {
    width: 100px !important;
  }

  /deep/ .el-checkbox__label {
    padding-left: 5px;
    font-family: 'Segoe UI', 'Roboto', arial, sans-serif;
    font-size: 12px !important;
  }
</style>
